<template>
  <section class="section get-details">
    <div class="get-details__group">
      <div class="get-details__form-wrapper">
        <h2>
          {{ $t("Join", { brand: $t(brand) }) }}
        </h2>
        <div class="get-details__form-container">
          <SelectTab
            v-if="isSelectTab"
            :options="cooperationOptions"
            :value="cooperationType"
            @select="selectCooperationType"
          >
            <template #option="slotProps">
              {{ $t(slotProps.option.value) }}
            </template>
          </SelectTab>
          <form class="get-details__form" @submit.prevent="onSubmit">
            <BaseInput
              v-model="state.name"
              :placeholder="$t('Name')"
              :inner-placeholder="$t('Your name')"
              :error="errors?.name?.[0]"
            />
            <BaseInput
              v-model="state.phone"
              label="phone"
              :placeholder="$t('Phone')"
              :inner-placeholder="phoneNumberFormat[countryCode].placeholder"
              :error="errors?.phone?.[0]"
              :validator="validators.phone(countryCode)"
              @is-error="isInputError"
              type="tel"
              :help-text="$t('Number format: +375 00 000 00 00')"
              :trim="true"
            />
            <BaseInput
              v-model="state.email"
              label="email"
              :placeholder="$t('Email')"
              :inner-placeholder="emailPlaceholder"
              :error="errors?.email?.[0]"
              :validator="validators.email"
              @is-error="isInputError"
            />
            <BaseInput
              v-model="state.companyName"
              :placeholder="$t('Facility name')"
              :inner-placeholder="$t('Object name')"
              :error="errors?.companyName?.[0]"
            />
            <AgreementCheckbox
              v-model="state.processPersonalData"
              class="get-details__agreement"
            />
            <BaseButton
              type="submit"
              :disabled="isDisabledButton"
              class="get-details__button"
              >{{ $t("Get an Offer") }}</BaseButton
            >
          </form>
        </div>
      </div>

      <div class="get-details__image">
        <img
          src="/image/get-details-section-image.webp"
          alt="pic"
          decoding="async"
          loading="lazy"
        />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { storeToRefs } from "pinia";
import SelectTab from "../input/SelectTab.vue";
import useInputValidation from "~/composables/useInputValidation";
import useWindowResize from "~/composables/useWindowResize";
import { useConfigStore } from "~/stores/config";
import { Brand, CooperationTypes, SuccessModalTypes } from "~/types/entities";
import { phoneNumberFormat } from "~/utils/validators/phone";
import BaseButton from "~/components/button/BaseButton.vue";
import BaseInput from "~/components/input/BaseInput.vue";
import BaseCheckBox from "~/components/input/BaseCheckbox.vue";
import validators from "~/utils/validators";
import { getOffer, sendBecomePartnerFields } from "~/api/formRequests";
import removePlusSymbol from "~/utils/removePlusSymbol";
import { successModalKey } from "~/symbols";
import isEqual from "lodash/isEqual";
import { hidePD } from "~/utils/analytics";

export const REQUESTS = {
  [CooperationTypes.PARTNER]: sendBecomePartnerFields,
  [CooperationTypes.CONNECT_COMPANY]: getOffer,
};
export const COOPERATION_OPTIONS_DEFAULT = [
  { value: "For Companies", id: CooperationTypes.CONNECT_COMPANY },
  { value: "For Partners", id: CooperationTypes.PARTNER },
];

export const COOPERATION_OPTIONS_TABLET = [
  { value: "Connect the company", id: CooperationTypes.CONNECT_COMPANY },
  { value: "Become a Partner", id: CooperationTypes.PARTNER },
];

export default defineNuxtComponent({
  components: {
    BaseCheckBox,
    BaseInput,
    BaseButton,
    SelectTab,
  },
  props: {
    isSelectTab: {
      type: Boolean,
      default: true,
    },
    cooperationType: {
      type: String,
      default: CooperationTypes.CONNECT_COMPANY,
    },
  },
  setup(props) {
    const { gtag } = useGtag();

    const { isMobileScreen } = useWindowResize();
    const { countryCode } = storeToRefs(useConfigStore());
    const { brand } = useRuntimeConfig().public;
    const defaultState = {
      companyName: "",
      name: "",
      email: "",
      phone: "",
      location: "default", // Remove this when backend will be changed to not recieve the location
      processPersonalData: false,
      country: countryCode.value,
    };
    const state = reactive({ ...defaultState });

    let showSuccessModal = (type: SuccessModalTypes): void => {};

    const errors = ref<any>(null);
    const isSentForm = ref(false);

    const { isValid, isInputError } = useInputValidation(state);
    const isDisabledButton = computed(() => !isValid.value || !!errors.value);
    const loading = ref(false);

    const cooperationType = ref(props.cooperationType);

    const gEventPayload = computed(() =>
      isEqual(state, defaultState) ? undefined : hidePD(state)
    );

    const cooperationOptions = computed(() =>
      isMobileScreen.value
        ? COOPERATION_OPTIONS_DEFAULT
        : COOPERATION_OPTIONS_TABLET
    );

    const preparedPhone = computed(() => removePlusSymbol(state.phone));

    watch(
      () => state,
      () => {
        if (errors.value) {
          errors.value = null;
        }
      },
      { deep: true }
    );

    async function onSubmit() {
      gtag("event", GetDetailsEvents.submit, gEventPayload.value);

      loading.value = true;
      try {
        await REQUESTS[cooperationType.value as keyof typeof REQUESTS]({
          ...state,
          phone: preparedPhone.value,
        });

        if (CooperationTypes.CONNECT_COMPANY === cooperationType.value) {
          showSuccessModal(SuccessModalTypes.CONNECT_COMPANY);
        } else {
          showSuccessModal(SuccessModalTypes.PARTNER);
        }
        resetState();
      } catch (e: any) {
        if (e && e.data && e.data.errors) {
          errors.value = e.data.errors;
        }
      }
      loading.value = false;
    }

    const emailPlaceholder = computed(() =>
      brand === Brand.sportbenefit
        ? "qwerty@sportbenefit.eu"
        : "qwerty@allsports.by"
    );

    function selectCooperationType(optionId: CooperationTypes) {
      gtag("event", GetDetailsEvents[optionId], gEventPayload.value);
      cooperationType.value = optionId;
    }

    function resetState() {
      Object.assign(state, defaultState);
    }

    onMounted(() => {
      showSuccessModal = inject(successModalKey, () => {});
    });

    return {
      state,
      loading,
      isSentForm,
      isDisabledButton,
      countryCode,
      emailPlaceholder,
      brand,
      errors,
      isInputError,
      onSubmit,
      validators,
      phoneNumberFormat,
      cooperationOptions,
      selectCooperationType,
      cooperationType,
    };
  },
});
</script>
